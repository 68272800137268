import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class RecentResultsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Recent Results"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container recent-results-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={14} textAlign={"left"}>
              <div>
                <h4>MEDICAL MALPRACTICE/WRONGFUL DEATH:</h4>
                <div>
                  Confidential settlement involving a 58 year old man who
                  presented to a local hospital suffering from confusion after
                  passing out at home. The patient was allowed to walk out of
                  the ER shortly after arrival. The hospital took no steps to
                  locate him or notify anyone of the patient's elopement. The
                  patient was later found deceased a short distance from the
                  hospital.
                </div>
              </div>
              <div>
                <h4>MOTOR VEHICLE ACCIDENT:</h4>
                <div>
                  $100,000 settlement for our client a 58 year old woman who
                  suffered a back injury in a motor vehicle accident. Our
                  client's vehicle was struck by another motorist attempting to
                  change lanes on Route 95 in Massachusetts. The insurance
                  company agreed to pay the full policy limits prior to suit.
                </div>
              </div>
              <div>
                <h4>MOTOR VEHICLE ACCIDENT:</h4>
                <div>
                  $125,000 settlement for our client a 22 year old woman who
                  sustained a back injury in a motor vehicle accident in
                  Northbridge, MA.
                </div>
              </div>
              <div>
                <h4>MOTOR VEHICLE ACCIDENT:</h4>
                <div>
                  $250,000 settlement for our client a 64 year old man who
                  suffered a back injury in a motor vehicle accident in
                  Woonsocket, RI. The insurance company initially denied
                  liability however the insurer agreed to pay the full policy
                  limits after the deposition of the defendant driver. Our
                  client's insurance company also agreed to pay the full
                  underinsured motorist limits.
                </div>
              </div>
              <div>
                <h4>Workplace Accident:</h4>
                <div>
                  Confidential six figure settlement for our client a 50 year
                  old warehouse worker who experienced a significant hand injury
                  while loading a trailer.
                </div>
              </div>
              <div>
                <h4>Trademark Infringement:</h4>
                <div>
                  Confidential five figure settlement for our client a local
                  food blogger whose recipes were plagiarized by a foreign
                  celebrity author and chef. Although our client did not
                  register her trademark in the United States, international law
                  does not require registration in order to pursue damages for
                  unauthorized use of proprietary content
                </div>
              </div>
              <div>
                <h4>Motor vehicle accident:</h4>
                <div>
                  $500,000.00 Settlement for a 30 year old woman and her young
                  daughter injured in a motor vehicle accident.
                </div>
              </div>
              <div>
                <h4>Medical Malpractice:</h4>
                <div>
                  Confidential Six Figure Settlement for a 56 year old man who
                  suffered an undiagnosed heart attack.
                </div>
              </div>
              <div>
                <h4>Premises Liability:</h4>
                <div>
                  Confidential Six Figure Settlement for the estate of a 45 year
                  old motorcycle operator who was killed by a bar patron who was
                  overserved.
                </div>
              </div>
              <div>
                <h4>Medical Malpractice:</h4>
                <div>
                  Confidential Six Figure Settlement for the estate of a 90 year
                  old man who died as a result of an untreated infection.
                </div>
              </div>
              <div>
                <h4>Workplace Accident:</h4>
                <div>
                  $240,000.00 Settlement for a 35 year old man who injured his
                  hand when it was caught in a food processing machine at his
                  place of work.
                </div>
              </div>
              <div>
                <h4>Slip and Fall:</h4>
                <div>
                  $120,000.00 settlement for a visiting nurse who slipped and
                  fell on an icy driveway injuring her shoulder.
                </div>
              </div>
              <div>
                <h4>Gymnastic Studio Accident:</h4>
                <div>
                  $240,000.00 Settlement for a young gymnast who fell from an
                  apparatus during a training session injuring her elbow.
                </div>
              </div>
              <div>
                <h4>Slip and Fall:</h4>
                <div>
                  $90,000 settlement for our client who suffered a shoulder
                  injury as a result of slipping on ice in a store parking lot.
                </div>
              </div>
              <div>
                <h4>Motor vehicle accident:</h4>
                <div>
                  $90,000 settlement for our client a bicyclist struck by a
                  passing motorist.
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Recent Results" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              fluid(maxWidth: 720) {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;
